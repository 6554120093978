.loginContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.inputControlWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.25rem;
}

.inputField {
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: inherit;
  height: auto;
  padding: 0.75rem 1.25rem;
  border: none;
  outline: none;
  border-radius: 2rem;
  color: #121212;
  background: #f1f5f9;
  text-transform: unset;
  text-rendering: optimizeLegibility;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.input-submit-button {
  font-family: inherit;
  font-size: 1rem;
  font-weight: 500;
  line-height: inherit;
  cursor: pointer;
  height: auto;
  width: 12rem;
  padding: 0.65rem 1.25rem;
  border: none;
  outline: none;
  border-radius: 2rem;
  color: #f9f9f9;
  background: transparent;
  border: 1px solid #dadce0;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  text-transform: capitalize;
  text-rendering: optimizeLegibility;
}
.logo {
  max-width: 150px;
  max-height: 150px;
}
.titleText {
  font-family: "boucherie-block", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 15px;
  color: #f9f9f9;
}

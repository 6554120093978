.adminContainer {
  display: flex;
  flex-direction: column;
  padding: 25px 15px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.adminNavbar {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.adminLogo {
  width: 100px;
  height: auto;
}

.adminTableContainer {
  width: 100%;
  overflow-x: auto;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
}

.adminTable {
  width: 100%;
  display: table;
  border-spacing: 0;
  border-collapse: collapse;
  min-width: 650px;
}

.adminTableHead {
  display: table-header-group;
}

.adminTableRow {
  color: inherit;
  display: table-row;
  outline: 0;
  vertical-align: middle;
}

.adminTableCellHead {
  color: #fff;
  font-weight: 700;
  line-height: 1.5rem;
}

.adminTableCell {
  display: table-cell;
  padding: 16px;
  font-size: 1rem;
  text-align: left;
  font-weight: 400;
  line-height: 1.43;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  letter-spacing: 0.01071em;
  vertical-align: inherit;
}

.adminTableCellBody {
  color: #fff;
  font-size: 0.75rem;
}

.adminTableBody {
  display: table-row-group;
}

.adminPagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  margin-top: 0;
  margin-bottom: 1rem;
}
.adminPageWrapper {
  display: list-item;
  text-align: -webkit-match-parent;
}

.adminPageItem {
  padding: 0.375rem 0.75rem;
  position: relative;
  display: block;
  color: #ac8454;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  cursor: pointer;
}
.adminActiveItem {
  z-index: 3;
  color: #fff;
  background-color: #ac8454;
  border-color: #ac8454;
}

.adminheaderContainer {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.pageTitle {
  color: #fff;
}

.adminSearchWrapper {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 15px 0;
}

.adminBtn {
  margin: 0 5px;
  padding: 0 2.3rem;
  height: 2.1rem;
  line-height: 2.8rem;
  border-radius: 7px;
  background-color: #ac8454;
  box-shadow: 0 4px 14px 0 rgba(81, 92, 43, 0.39);
  color: white;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  border: none;
  font-size: inherit;
  outline: none;
}

.adminBtn:hover {
  background: #ac8454;
  box-shadow: 0 4px 14px 0 rgba(42, 48, 22, 0.39);
}

.inputControl {
  display: block;
  width: 13rem;
  height: 2.1rem;
  padding: 0.2rem 0.75rem;
  margin-top: 23px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #f0f1f5;
  border-radius: 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.inputControl:focus {
  outline: none !important;
  border: 0.01rem solid #ac8454;
}
.inputControl:hover {
  outline: none !important;
  border: 0.01rem solid #ac8454;
}

.inputControlWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.25rem;
}

.inputField {
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: inherit;
  height: auto;
  padding: 0.75rem 1.25rem;
  border: none;
  outline: none;
  border-radius: 2rem;
  color: #121212;
  background: #f1f5f9;
  text-transform: unset;
  text-rendering: optimizeLegibility;
}

.input-submit-button {
  font-family: inherit;
  font-size: 1rem;
  font-weight: 500;
  line-height: inherit;
  cursor: pointer;
  height: auto;
  width: 12rem;
  padding: 0.65rem 1.25rem;
  border: none;
  outline: none;
  border-radius: 2rem;
  color: #f9f9f9;
  background: transparent;
  border: 1px solid #dadce0;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  text-transform: capitalize;
  text-rendering: optimizeLegibility;
}

.adminTagsContainer {
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  max-width: 270px;
  padding-left: 14px;
  border: 1px grey solid;
  border-radius: 5px;
  color: black;
}

.adminTagsInput {
  width: 45px;
  border: none;
  border-radius: 5px;
  padding: 10px;
}

.adminTag {
  display: flex;
  align-items: center;
  margin: 7px 0;
  margin-right: 10px;
  padding: 0 10px;
  padding-right: 5px;
  border: 1px solid #ac8454;
  border-radius: 5px;
  background-color: #ac8454;
  white-space: nowrap;
  color: white;
}

.adminTagButton {
  display: flex;
  padding: 6px;
  border: none;
  background-color: unset;
  cursor: pointer;
  color: white;
}

.contactContainer {
  background-color: #1c1b19;
}

.contactTopMenu {
  display: none;
}

.contactLogoWrapper {
  display: flex;
  align-items: center;
  color: #ad8457;
}

.contactLogoWrapper > h2 {
  color: #ad8457;
}

.contactMenuLogo {
  max-width: 80px;
}

.contactMenuLinks {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contactMenuLinks > a {
  text-decoration: none;
  color: white;
}

.contactHero {
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contactHero::after {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  background-color: #353530;
  content: "";
  opacity: 0.7;
  background-size: contain;
}

.contactHeroContent {
  padding: 140px 0;
  text-align: center;
  width: 100%;
}

.contactHeroContent > span {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 4px;
  text-transform: uppercase;
  margin-bottom: 45px;
  color: white;
}

.contactHeroContent > h2 {
  font-size: 72px;
  letter-spacing: -1px;
  color: white;
}

.contactCard {
  padding: 40px 10px;
  font-size: 14px;
  background-color: #353530;
  border: 2px solid #40403b;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contactCardIcon {
  font-size: 40px;
  color: #ad8457;
  line-height: 1;
}

.contactCardInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 15px;
}

.contactCardInfo > h3 {
  margin-top: 0;
  margin-bottom: 10px;
}

.contactCardInfo > span {
  font-family: "futura-pt", sans-serif;
  font-weight: 300;
  color: #a3a3a3;
}

.contactMapWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contactMap {
  max-width: 100%;
  margin: 0 auto;
}

/*.contactFormWrapper {
  background-color: #353530;
  padding: 100px;
  margin: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: space-around;
}*/

.aboutTitleWrapper {
  text-align: center;
  color: white;
}

.aboutTitleWrapper > h2 {
  font-size: 70px;
  line-height: 1.125;
  letter-spacing: -1px;
}

.previewCardInfo {
  background-color: #353530;
  padding: 80px 10px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 30px;
}

.previewCardImg {
  height: 100%;
}

.previewTextWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  max-width: 400px;
}

.previewTextWrapper > h3 {
  font-size: 22px;
  color: #fff;
  font-family: "boucherie-block", sans-serif;
  font-style: normal;
  font-weight: 300;
  margin: 0px;
}

.previewTextWrapper > p {
  margin-bottom: 1.5rem !important;
  font-family: "futura-pt", sans-serif;
  font-style: block;
  font-weight: 300;
  color: #a3a3a3;
}
/* For large devices */
@media screen and (min-width: 992px) {
  .previewCardInfo {
    background-color: #353530;
    padding: 80px 30px;
    margin: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 30px;
  }
}

@media screen and (min-width: 1200px) {
  .contactTopMenu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 50px;

    background-color: #353530;
  }
  .previewCardInfo {
    background-color: #353530;
    padding: 80px 30px;
    margin: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 30px;
  }
}

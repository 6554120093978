.profileContainer {
  display: flex;
}

.profilePreviewSection {
  flex: 8;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profielPreviewConatiner {
  height: 100vh;
  width: 100%;
  background-color: white;
  border: 1px solid #f0f1f5;
  overflow: auto;
  display: flex;
  flex-direction: column;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  position: relative;
}

.profielPreviewConatiner::-webkit-scrollbar {
  display: none;
}

.profileHeaderImgWrapper {
  position: relative;
}

.profileHeaderImg {
  width: 100%;
  min-height: 600px;
  object-fit: cover;
}

.profileHeaderImgWrapperShadow {
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: inset 0px -420px 161px -73px rgba(0, 0, 0, 0.45);
  bottom: 0;
  left: 0;
}

.profilePersonalInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  width: 100%;
  background-color: transparent;
}

.profileAvatarWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-self: center;
}

.profileAvatar {
  height: 90px;
  width: 90px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}

.profileInfoConatiner {
  padding: 0;
  position: absolute;
  top: 270px;
  width: 100%;
}

.profilPersonalInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  width: 100%;
  background-color: transparent;
}

.profileTextInfo {
  display: block;
  padding: 0;
  background-clip: padding-box;
  border: none;
  font-size: 1.1rem;
  font-weight: 700;
  text-align: center;
  width: 100%;
  background-color: transparent;
}

.profileContactLinksWrapper {
  margin-top: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.profileContactLinks {
  border-radius: 50%;
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.profileTextAreaContainer {
  margin-top: 25px;
  border-radius: 12px;
  padding: 10px;
  margin-right: 10px;
  margin-left: 10px;
}

.profileTextarea {
  height: 130px;
  font-family: inherit;
  font-weight: 400;
  font-size: 1rem;
  background-color: transparent;
  text-align: center;
}

.profileLinksCard {
  margin: 10px 10px;
  padding: 6px 0px;
  border-radius: 8px;
}

.profilecCardTitle {
  margin-left: 10px;
}

.profileGrid {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  /*max-width: 380px;*/
}

.profileSlide {
  margin-top: 7px;
  margin-bottom: 15px;
}

.profileGridItem {
  border-radius: 50%;
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.svg-social-icon {
  width: 4.5em;
  height: 4.5em;
}

.share-btn {
  margin: 0.5rem;
}

.profileViewButton {
  margin: 10px 0;
  padding: 0 3.5rem;
  height: 2.1rem;
  line-height: 2.8rem;
  border-radius: 7px;
  background-color: #ac8454;
  box-shadow: 0 4px 14px 0 rgba(66, 82, 28, 0.267);
  color: white;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  border: none;
  font-size: inherit;
  outline: none;
}

.noFontText {
  font-family: Arial, Helvetica, sans-serif !important;
}

.accordion {
  margin: 2rem auto;
}

.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}

.accordion-title {
  padding: 1rem;
}

.accordion-content {
}

@media only screen and (min-width: 992px) {
  .profielPreviewConatiner {
    height: 100vh;
    max-width: 360px;
  }
}

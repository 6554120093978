.homeContainer {
  display: flex;
  flex-direction: column;
  background-color: #1c1b19;
}

.homeSection {
  /*max-width: 968px;*/
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.homeNavbar {
  height: 40px;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.topMenuContainer {
  display: none;
}

.topMenuLink {
  padding: 0 15px;
}

.topMenuContainer > a {
  text-decoration: none;
  color: white;
}

.topMenuContainer > a:hover {
  color: #ad8457;
}

.homeMenuMobile {
  position: fixed;
  background-color: #1c1b19;
  width: 80%;
  height: 100%;
  top: 0;
  right: 0;
  box-shadow: -2px 0 4px hsla(152, 24%, 15%, 0.1);
  padding: 4rem 0 0 3rem;
  transition: 0.3s;
  z-index: 100;
}

.homeMenuMobileClose {
  font-size: 1.5rem;
  position: absolute;
  top: 1rem;
  right: 1.25rem;
  cursor: pointer;
  color: white;
  fill: white;
}

.homeMobileLinks > a {
  color: white;
  text-decoration: none;
}

.homeLogo {
  max-width: 120px;
}

.homeTitle {
  font-family: "boucherie-block", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #f9f9f9;
}

.homeButton {
  margin: 0 8px;
  font-family: inherit;
  font-size: 14px;
  font-weight: 600;
  line-height: inherit;
  cursor: pointer;
  height: auto;
  border: none;
  outline: none;
  border-radius: 2rem;
  color: #f9f9f9;
  background: transparent;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  text-transform: capitalize;
  text-rendering: optimizeLegibility;
  transition: all 0.3s ease-out 0s;
}

.homeButton:hover {
  color: #fcd462;
}

.homeContent {
  height: 950px;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  /*overflow: hidden;*/
  justify-content: start;
  /*background-position: center;
  background-size: cover;
  transition: 0.3s;*/
  z-index: 1;
}

@keyframes fadeIn {
  from {
    transform: scale(1.1);
  }
}

.home__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-position: center;
  z-index: -2;
  transition: 0.3s;
  animation: fadeIn 5s infinite alternate;
}

.bannerOverlay {
  background-color: #353530;
  z-index: -1;
  opacity: 0.6;
}

.heroContainer {
  margin: auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #dadce0;
  padding: 0 10px;
  max-width: 100%;
}

.homeCaptionText {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 3px;
  padding-bottom: 30px;
}

.mainText {
  font-size: 60px;
  line-height: 1.1;
  font-weight: 400;
  letter-spacing: -6px;
  padding-bottom: 26px;
  text-align: center;
}

.heroButtonsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.homeHeroButton {
  text-decoration: none;
  display: inline-block;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  padding: 0 48px;
  font-size: 14px;
  line-height: 60px;
  border-radius: 0px;
  cursor: pointer;
  transition: all 0.4s ease-out 0s;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #a3a3a3;
  border: 2px solid #ac8454;
  width: 100px;
}

.homeFilledButton {
  background-color: #ac8454;
  color: #fff;
}

.homeHeroButton:hover {
  border-color: rgba(255, 255, 255, 0.2);
  color: #fff;
  background-color: transparent;
}

.homeBorderButton {
  border-color: rgba(255, 255, 255, 0.2);
  color: #fff;
  margin-top: 24px;
}

.homeBorderButton:hover {
  background-color: #ac8454;
}

.heroText {
  text-align: center;
  margin-top: 25px;
  font-size: 20px;
  max-width: 550px;
}

.textWhite {
  color: #fff;
}

.text-center {
  text-align: center !important;
}

.pb-155 {
  padding-bottom: 155px;
}

.pt-115 {
  padding-top: 115px;
}

.mb-50 {
  margin-bottom: 50px;
}

.clumn4Grid {
  grid-template-columns: repeat(1, 1fr);
  gap: 2.5rem 3rem;
}

.clumn3Grid {
  grid-template-columns: repeat(1, 1fr);
  gap: 2.5rem 3rem;
}

.homeSectionFluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.sectionTitle {
  color: #fff;
  font-size: 70px;
  line-height: 1.125;
}

.sectionTitleIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 30px;
}

.smallIcon {
  max-width: 80px;
}

.sectionTitleIcon::before {
  content: "";
  width: 160px;
  height: 1px;
  background-color: #40403b;
}

.sectionTitleIcon::after {
  content: "";
  width: 160px;
  height: 1px;
  background-color: #40403b;
}

.titleTag {
  color: #a3a3a3;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 3px;
  padding-bottom: 20px;
}

.homeRow {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.featureCardWrapper {
  position: relative;
  width: 100%;
}

.cardItem {
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 450px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  backdrop-filter: blur(25px);
}

.cardItem::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 55%);
  z-index: -1;
  transition: all 0.4s ease 0s;
}

.cardTitleWrapper {
  text-align: center;
  opacity: 1;
  visibility: visible;
  width: 100%;
  transition: opacity 0.4s ease 0.4s, visibility 0.4s ease 0.4s;
}

.homeCardTitle {
  text-align: center;
  color: #fff !important;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
}

.displayCardTextHover {
  color: #fff !important;
  transition: opacity 0.4s ease 0s, visibility 0.4s ease 0s;
}

.subtitleSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 22px;
}
.video {
  max-width: 100%;
  height: auto;
}
.videoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.videoContent {
  position: relative;
}

.videoButton {
  position: absolute;
  right: 1rem;
  bottom: -1rem;
  padding: 1rem 1.5rem;
  border: none;
  display: inline-block;
  background-color: #ac8454;
  color: #f9f9f9;
  font-weight: 600;
  transition: 0.3s;
}

.homeGrid {
  display: grid;
}

.homeFeatureContainer {
  padding: 3rem 0;
}

.featureCard {
  padding: 45px;
  font-size: 14px;
  background-color: #353530;
  border: 2px solid #40403b;
  position: relative;
  margin-top: 30px;
}

.featureCardText {
  font-size: 30px;
  margin-top: 50px;
  margin-bottom: 30px;
  letter-spacing: -1px;
}

.featureCardCount {
  line-height: 1;
  position: absolute;
  right: 0;
  top: -10px;
  font-size: 120px;
  color: #3d3c38;
}

.experinceContainer {
  background-color: #6c757d;
}

.experinceTitle {
  font-size: 40px;
}

.galleryGrid {
  margin: 70px 0;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(29, 5vw);
  gap: 1rem 3rem;
}

.gallery__item--1 {
  grid-column-start: 1;
  grid-column-end: 8;
  grid-row-start: 1;
  grid-row-end: 6;
}

.gallery__item--2 {
  grid-column-start: 1;
  grid-column-end: 8;
  grid-row-start: 7;
  grid-row-end: 13;
}

.gallery__item--3 {
  grid-column-start: 1;
  grid-column-end: 8;
  grid-row-start: 14;
  grid-row-end: 21;
}

.gallery__item--4 {
  grid-column-start: 1;
  grid-column-end: 8;
  grid-row-start: 22;
  grid-row-end: 29;
}

.galleryImgContainer {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.galleryImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.3s;
}

.galleryImg:hover {
  transform: scale(1.1);
}

.experinceInfoTextContainer {
  position: absolute;
  left: 30px;
  bottom: 30px;
  z-index: 2;
  right: 30px;
}

.counterContainer {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: #353530;
}

.counterWrapper {
  padding: 40px 25px;
}

.counterColumn {
  display: flex;
  flex-direction: column;
}

.middleTextSize {
  font-size: 22px;
}

.featuredCardIcon {
  margin-bottom: 30px;
  font-size: 40px;
  color: #ad8457;
  line-height: 1;
}

.informativeCards {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footerContainer {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: flex-start;
}

.footerVision {
  display: flex;
  flex-direction: column;
  max-width: 350px;
  color: #bababa;
}

.footerVision > span {
  font-family: "futura-pt", sans-serif;
  font-weight: 300;
}

.footerMenu {
  width: 100%;
}

.footerMenuLinks {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
}
.footerMenuLinksColumn {
  display: flex;
  flex-direction: column;
}
.footerLink {
  margin-right: 50px;
  margin-bottom: 20px;
  width: 100%;
}

.footerLink > a {
  text-decoration: none;
  color: white;
}

.footerLink > a:hover {
  color: #ad8457;
}

.footerContactContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 250px;
}

.footerContactCard {
  padding: 15px 10px;
  display: flex;
}

.footerInfoWrapper {
  margin-left: 15px;
  color: #fff;
}

/*=============== BREAKPOINTS ===============*/
/* For small devices */
@media screen and (max-width: 320px) {
  .homeNavbar {
    padding: 15px 10px;
  }
  .homeLogo {
    width: 260px;
    height: auto;
  }
  .homeButton {
    margin: 0 5px;
    font-family: inherit;
    font-size: 0.5rem;
    font-weight: 500;
    height: auto;
    width: 4rem;
    padding: 0.45rem 1rem;
  }
  .heroText {
    font-size: 15px;
  }
  .homeFeatureContainer {
    grid-template-columns: 0.6fr;
    justify-content: center;
  }
}

/* For medium devices */
@media screen and (max-width: 576px) {
}

@media screen and (min-width: 767px) {
}

/* For large devices */
@media screen and (min-width: 992px) {
  .homeFeatureContainer {
    justify-content: center;
    column-gap: 5rem;
  }
  .clumn4Grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 2.5rem 3rem;
  }
  .clumn3Grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem 3rem;
  }
  .counterContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .footerContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
}

@media screen and (min-width: 1200px) {
  .topMenuContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mainText {
    font-size: 80px;
  }
  .heroButtonsWrapper {
    flex-direction: row;
  }

  .homeBorderButton {
    margin-top: 0px;
    margin-left: 24px;
  }
  .clumn4Grid {
    grid-template-columns: repeat(4, 1fr);
    gap: 2.5rem 3rem;
  }
  .clumn3Grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem 3rem;
  }
  .homeFeatureContainer {
    padding: 3rem 0;
    gap: 2.5rem 3rem;
  }
  .counterContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .video {
    width: auto;
    height: 600px;
  }
  .galleryGrid {
    margin-top: 70px;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(8, 5vw);
    grid-gap: 15px;
  }

  .galleryImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .gallery__item--1 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 4;
  }

  .gallery__item--2 {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 4;
  }

  .gallery__item--3 {
    grid-column-start: 5;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 8;
  }

  .gallery__item--4 {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 4;
    grid-row-end: 8;
  }
  .footerMenu {
    width: 100%;
    margin-left: 100px;
  }
  .footerContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
}

.contactContainer {
  background-color: #1c1b19;
}

.contactTopMenu {
  display: none;
}

.contactLogoWrapper {
  display: flex;
  align-items: center;
  color: #ad8457;
}

.contactLogoWrapper > h2 {
  color: #ad8457;
}

.contactMenuLogo {
  max-width: 80px;
}

.contactMenuLinks {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.contactMenuLinks > a {
  text-decoration: none;
  color: white;
}

.mobileMenuContainer {
  height: 40px;
  padding: 15px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  fill: white;
}

.contactHero {
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contactHero::after {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  background-color: #353530;
  content: "";
  opacity: 0.7;
  background-size: contain;
}

.contactHeroContent {
  padding: 140px 0;
  text-align: center;
  width: 100%;
}

.contactHeroContent > span {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 4px;
  text-transform: uppercase;
  margin-bottom: 45px;
  color: white;
}

.contactHeroContent > h2 {
  font-size: 72px;
  letter-spacing: -1px;
  color: white;
}

.contactCard {
  padding: 40px 10px;
  font-size: 14px;
  background-color: #353530;
  border: 2px solid #40403b;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contactCardIcon {
  font-size: 40px;
  color: #ad8457;
  line-height: 1;
}

.contactCardInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 15px;
}

.contactCardInfo > h3 {
  margin-top: 0;
  margin-bottom: 10px;
}

.contactCardInfo > span {
  font-family: "futura-pt", sans-serif;
  font-weight: 300;
  color: #a3a3a3;
}

.contactMapWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contactMap {
  max-width: 100%;
  margin: 0 auto;
}

.contactFormWrapper {
  background-color: #353530;
  padding: 10px;
  margin: 15px;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: space-around;
}

.formInput {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-items: stretch;
}

.formInput > input {
  color: white;
  font-family: "boucherie-block", sans-serif;
}

.formInput > textarea {
  color: white;
  font-family: "boucherie-block", sans-serif;
}

.fortmTextarea {
  width: 100%;
}

.formInput > input {
  border: none;
  width: 100%;
  height: 70px;
  background-color: #1c1b19;
  padding: 0 40px;
}

.formInput > textarea {
  border: none;
  width: 100%;
  height: 180px;
  background-color: #1c1b19;
  padding: 20px 40px;
}

.formInput > button {
  margin-top: 15px;
  text-decoration: none;
  display: inline-block;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  padding: 0 18px;
  font-size: 14px;
  line-height: 60px;
  border-radius: 0px;
  cursor: pointer;
  transition: all 0.4s ease-out 0s;
  text-transform: uppercase;
  letter-spacing: 2px;
  border: 2px solid #ac8454;
  width: 100px;
  background-color: #ac8454;
  color: #fff;
}

@media screen and (min-width: 1200px) {
  .contactTopMenu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 50px;

    background-color: #353530;
  }
  .contactFormWrapper {
    background-color: #353530;
    padding: 100px;
    margin: 50px;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: space-around;
  }

  .formInput {
    width: 40%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    align-items: stretch;
  }

  .fortmTextarea {
    width: 90%;
  }

  .formInput > input {
    border: none;
    width: 100%;
    height: 70px;
    background-color: #1c1b19;
    padding: 0 40px;
  }

  .formInput > textarea {
    border: none;
    width: 100%;
    height: 180px;
    background-color: #1c1b19;
    padding: 20px 40px;
  }
}

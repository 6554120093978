.container {
  display: flex;
}

.formControl {
  flex: 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.previewSection {
  flex: 8;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.saveButton {
  margin: 10px 0;
  padding: 0 3.5rem;
  height: 2.1rem;
  line-height: 2.8rem;
  border-radius: 7px;
  background-color: #ac8454;
  box-shadow: 0 4px 14px 0 rgba(66, 82, 28, 0.267);
  color: white;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  border: none;
  font-size: inherit;
  outline: none;
}

.saveButton:hover {
  background: #af8b5f;
  box-shadow: 0 6px 20px rgba(148, 146, 146, 0.23);
}

.inputControl {
  display: block;
  width: 13rem;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  margin-top: 23px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: inherit;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #f0f1f5;
  border-radius: 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.inputControl:focus {
  outline: none !important;
  border: 0.01rem solid #ac8454;
}
.inputControl:hover {
  outline: none !important;
  border: 0.01rem solid #ac8454;
}

.inputControlEditable {
  display: block;
  padding: 0;
  background-clip: padding-box;
  border: none;
  font-size: 1.1rem;
  font-weight: 700;
  text-align: center;
  width: 100%;
  background-color: transparent;
}
.inputControlEditable:focus {
  outline: none !important;
  border: 0.01rem solid #ac8454;
}
.inputControlEditable:hover {
  outline: none !important;
  border: 0.01rem solid #ac8454;
}

.subInfo {
  font-size: 1rem;
  font-weight: 700;
}

.textAreaContainer {
  margin-top: 25px;
  border-radius: 12px;
  padding: 10px;
  margin-right: 10px;
  margin-left: 10px;
}

.textarea {
  height: 130px;
  font-family: inherit;
  font-weight: 400;
  font-size: 1rem;
  background-color: transparent;
  text-align: center;
}

.previewConatiner {
  height: 100vh;
  width: 100%;
  background-color: white;
  border: 1px solid #f0f1f5;
  overflow: auto;
  display: flex;
  flex-direction: column;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  position: relative;
}

.previewConatiner::-webkit-scrollbar {
  display: none;
}

.headerImgWrapper {
  position: relative;
}

.headerImg {
  width: 100%;
  min-height: 600px;
  object-fit: cover;
}

.headerImgWrapperShadow {
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: inset 0px -420px 161px -73px rgba(0, 0, 0, 0.45);
  bottom: 0;
  left: 0;
}

.uploadHeaderBtn {
  position: absolute;
  background-color: white;
  top: 30px;
  right: 50px;
  border-radius: 8px;
  padding: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}

.closeBtnWrapper {
  position: absolute;
  top: 35px;
  right: 20px;
  cursor: pointer;
  z-index: 1;
  color: #fff;
  font-size: 30px;
}

.colorBtnWrapper {
  position: absolute;
  top: 30px;
  left: 20px;
  cursor: pointer;
  z-index: 1;
}

.textcolorBtnWrapper {
  position: absolute;
  top: 30px;
  left: 75px;
  cursor: pointer;
  z-index: 1;
}

.cardscolorBtnWrapper {
  position: absolute;
  top: 30px;
  left: 130px;
  cursor: pointer;
  z-index: 1;
}

.personalInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  width: 100%;
  background-color: transparent;
}

.avatarWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-self: center;
}

.uploadAvatarBtn {
  position: absolute;
  background-color: white;
  bottom: 0px;
  right: 0px;
  border-radius: 8px;
  padding: 5px;
  cursor: pointer;
  z-index: 0;
}

.avatar {
  height: 90px;
  width: 90px;
  border-radius: 50%;
  object-fit: cover;
}

.infoConatiner {
  padding: 0;
  position: absolute;
  top: 270px;
  width: 100%;
}

.contactLinksWrapper {
  margin-top: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.contactLinks {
  border-radius: 50%;
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.addInfoLinks {
  color: white;
  text-decoration: none;
  background-color: gray;
  text-align: center;
}

.linksCard {
  margin: 10px 10px;
  padding: 6px 0px;
  background-color: #fff;
  border-radius: 8px;
}

.swipperContainer {
  padding: 8px 0 !important;
}

.cardTitle {
  margin-left: 10px;
  text-align: left;
  font-size: 1.4rem;
}

.grid {
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.gridSlide {
  margin-top: 7px;
  margin-bottom: 15px;
}

.gridItem {
  border-radius: 50%;
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.addGridItem {
  background-color: #635f5a;
  color: white;
}

.svg-social-icon {
  width: 2em;
  height: 2em;
}

.customLink {
  margin: 10px 0;
  padding: 0 1.5rem;
  height: 2.81rem;
  line-height: 2.8rem;
  border-radius: 7px;
  background: #fff;
  color: #696969;
  box-shadow: 0 4px 14px 0 rgb(0 0 0 / 10%);
}

.circleCustomLink {
  background: #fff;
  color: #696969;
  box-shadow: 0 4px 14px 0 rgb(0 0 0 / 10%);
}

@media only screen and (min-width: 992px) {
  .previewConatiner {
    height: 100vh;
    max-width: 360px;
  }
}

.orderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #1c1b19;
  padding: 25px 15px;
}

.orderWrapper {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 15px 0;
}

.orderFormWrapper {
  background-color: #353530;
  padding: 20px 35px;
  margin: 15px;
}

.orderBtn {
  margin: 10px 5px;
  /*padding: 0 2.3rem;*/
  height: 2.1rem;
  width: 100%;
  line-height: 2.8rem;
  border-radius: 7px;
  background-color: #ac8454;
  box-shadow: 0 4px 14px 0 rgba(81, 92, 43, 0.39);
  color: white;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  border: none;
  font-size: inherit;
  outline: none;
}

.orderBtn:hover {
  background: #ac8454;
  box-shadow: 0 4px 14px 0 rgba(42, 48, 22, 0.39);
}

.orderInputControl {
  border: none;
  display: block;
  width: 13rem;
  height: 3rem;
  padding: 0.2rem 0.75rem;
  margin-top: 23px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #1c1b19;
  background-clip: padding-box;
  text-transform: unset;
  text-rendering: optimizeLegibility;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
